import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';

export default function Accounts() {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    isLimitPages: Number(query.p || 1) > 10000,
    body: {
      start: query.p || '1',
      length:JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')) ,
    },
  });

  const handleChangePageSize = e => {
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const accountListRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/account/accountList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    if (!state.isLimitPages) {
      accountListRequest.run(state.body);
    }
  }, [state]);

  if (state.isLimitPages) {
    return window.location.replace('/error');
  }

  if (accountListRequest.loading) {
    return <Loading />;
  }

  const data = accountListRequest.data?.account_list || [];
  const counts = accountListRequest.data?.counts || 0;
  const account_count = accountListRequest.data?.account_count || 0;
  const totalPage = Math.ceil(Number(account_count) / state.body.length);
  const length=state.body.length;
  window.sessionStorage.setItem('2',JSON.stringify(state.body.length))

  return (
    <main role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 font-weight-normal mb-0">
              Top Accounts by CMP Balance&nbsp;<span className="small text-secondary"></span>
            </h1>
          </div>
        </div>
      </div>

      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body">
            <div className="d-md-flex justify-content-between mb-4">
              <p className="mb-2 mb-md-0">
                <i className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>A total of &gt; {counts} accounts found
                <span className="d-block text-secondary small">(Showing the last {account_count} top accounts only)</span>
              </p>
              <Pager path="/accounts" current={state.body.start} total={totalPage} />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover" style={{width: '100%'}}>
                <thead className="thead-light">
                  <tr>
                    <th width="40" scope="col">
                      Rank
                    </th>
                    <th scope="col" width="370">
                      Address
                    </th>
                    <th className="remove-icon" scope="col">
                      Name Tag
                    </th>
                    <th scope="col">
                      <i className="fa fa-angle-down text-secondary"></i> Balance
                    </th>
                    <th scope="col">Percentage</th>
                    <th scope="col">Txn Count</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    // 账户类型, 1-account 2-contract
                    return (
                      <tr key={item.order_no}>
                        <td>{i + 1 + Number(state.body.length) * (state.body.start - 1)}</td>
                        <td>
                          {String(item.account_type) === '2' ? <i data-toggle="tooltip" title="" className="far fa-file-alt text-secondary mr-1" data-original-title="Contract"></i> : undefined}
                          <a href={`/address/${item.account_address}`}>{item.account_address}</a>
                          {item.account_lock ? (
                            <a
                              href="https://support.hbfile.net/hc/en-us/articles/900003880226-Caduceus-Global-Will-Transfer-CMP-To-Caduceus"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="View CMP Transfer Information"
                            >
                              <i className="far fa-lock-alt text-secondary ml-1"></i>
                            </a>
                          ) : undefined}
                        </td>
                        <td>{item.name_tag}</td>
                        <td>{item.balance} CMP</td>
                        <td>{item.percentage}</td>
                        <td>{item.trade_count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <form method="post">
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                  Show
                  <select onChange={handleChangePageSize} className="custom-select custom-select-xs mx-2" defaultValue={length}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Records
                </div>
                <Pager path="/accounts" current={state.body.start} total={totalPage} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
