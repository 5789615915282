import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';

export default function Tokens() {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      // length: JSON.parse(sessionStorage.getItem('2')),
      length:JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')) ,
    },
  });

  const handleChangePageSize = e => {
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const tokensRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/marketCap/tokenList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    tokensRequest.run(state.body);
  }, [state]);

  if (tokensRequest.loading) {
    return <Loading />;
  }

  const data = tokensRequest.data?.token_list || [];
  const counts = tokensRequest.data?.counts || 0;
  const token_contracts_counts = tokensRequest.data?.token_contracts_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);
  const length=state.body.length;
  
  window.sessionStorage.setItem('2',JSON.stringify(state.body.length))

  return (
    <main id="content" role="main">
      <div className="container">
        <div id="ContentPlaceHolder1_divTokensTitle" className="py-3">
          <div className="mb-1 mb-md-0">
            <h1 id="ContentPlaceHolder1_h1Tag" className="h4 d-flex align-items-center mb-0">
              Token Tracker
              <a
                className="u-label u-label--xs u-label--secondary rounded color-strong ml-2"
                href="https://docs.caduceuschain.com/#/en-us/dev/hrc20"
                target="_blank"
                rel="nofollow"
                data-toggle="tooltip"
                title=""
                data-original-title="External link to CRC-20 Information"
              >
                CRC-20
              </a>
            </h1>
          </div>
        </div>
      </div>

      <div className="container space-bottom-2">
        <div className="card">
          <div id="ContentPlaceHolder1_searchBarDiv" className="card-header d-md-flex justify-content-between align-items-center">
            <div className="mb-1 mb-md-0">
              <h2 className="card-header-title">CRC-20 Tokens By MarketCapitalization</h2>
            </div>
            <div className="d-flex ml-auto">
              <div className="position-relative order-1 order-md-2">
                {/* <a
                  id="searchFilterInvoker"
                  className="btn btn-sm btn-icon btn-primary"
                  href="#"
                  role="button"
                  aria-controls="searchFilter"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-unfold-target="#searchFilter"
                  data-unfold-type="css-animation"
                  data-unfold-duration="300"
                  data-unfold-delay="300"
                  data-unfold-hide-on-scroll="false"
                  data-unfold-animation-in="slideInUp"
                  data-unfold-animation-out="fadeOut"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Search for Token Name or Address"
                  onClick={e => {
                    e.preventDefault();
                    setShowSearchBox(!showSearchBox);
                  }}
                >
                  <i className="fa fa-search btn-icon__inner"></i>
                </a> */}
                <div
                  id="searchFilter"
                  className={`dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation ${showSearchBox ? 'slideInUp' : 'u-unfold--hidden'}`}
                  aria-labelledby="searchFilterInvoker"
                  style={{width: '310px', animationDuration: '300ms'}}
                >
                  <form action="/tokens" method="get" autoComplete="off" spellCheck="false" className="js-focus-state input-group input-group-sm w-100">
                    <input
                      id="searchtoken"
                      name="q"
                      type="search"
                      className="form-control ui-autocomplete-input list-unstyled py-3 mb-0"
                      placeholder="Search for Token Name or Address"
                      autoComplete="off"
                    />
                    <div className="input-group-append">
                      <button type="submit" className="btn btn-primary" data-toggle="tooltip" data-original-title="" title="">
                        Find
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="transfers" role="tabpanel" aria-labelledby="tokens-tab">
                <div id="ContentPlaceHolder1_divpagingpanel" className="d-md-flex justify-content-between align-items-center mb-4">
                  <p className="mb-2 mb-lg-0">
                    <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse" style={{display: 'none', marginTop: '4px'}}>
                      &nbsp;
                    </i>
                    Showing {counts} Token Contracts (From a total of {token_contracts_counts} Token Contracts)
                  </p>
                  <Pager current={state.body.start} total={totalPage} path="/tokens" />
                </div>
                <div className="stickyTableHead" id="divSTContainer">
                  <div id="ContentPlaceHolder1_divresult" className="table-responsive mb-2 mb-md-0">
                    <table className="table table-text-normal table-hover" id="tblResult">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" width="1" className="">
                            <div className="">#</div>
                          </th>
                          <th scope="col" width="30" className="">
                            <div className="">Token</div>
                          </th>
                          <th scope="col" width="5" className="">
                            <div className="">
                              <a href="/tokens?sort=price_usd&amp;order=desc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for descending sort">
                                Price
                              </a>
                            </div>
                          </th>
                          <th scope="col" width="5" className="">
                            <div className="">
                              <a
                                href="/tokens?sort=percent_change_24h&amp;order=desc"
                                data-toggle="tooltip"
                                data-boundary="viewport"
                                data-html="true"
                                title=""
                                data-original-title="Click for descending sort"
                              >
                                Change (%)
                              </a>
                            </div>
                          </th>
                          <th scope="col" width="5" className="text-nowrap">
                            <div className="">
                              <a
                                href="/tokens?sort=24h_volume_usd&amp;order=desc"
                                data-toggle="tooltip"
                                data-boundary="viewport"
                                data-html="true"
                                title=""
                                data-original-title="Click for descending sort"
                              >
                                Volume (24H)
                              </a>
                            </div>
                          </th>
                          <th scope="col" width="5" className="text-nowrap">
                            <div className="">
                              <strong>
                                <i className="fa fa-angle-down"></i>
                              </strong>
                              &nbsp;
                              <a
                                href="/tokens?sort=marketcap&amp;order=asc"
                                data-toggle="tooltip"
                                data-boundary="viewport"
                                data-html="true"
                                title=""
                                style={{textDecoration: 'underline'}}
                                data-original-title="Sorted in descending order, Click for ascending sort"
                              >
                                Market Cap
                              </a>
                              <i
                                className="far fa-question-circle text-muted"
                                data-placement="right"
                                data-boundary="viewport"
                                data-toggle="tooltip"
                                data-html="true"
                                data-title="<p className='text-white text-left mb-0'>Circulating Supply MarketCap - Calculated by multiplying the number of outstanding tokens with the current market price</p>"
                                data-original-title=""
                                title=""
                              ></i>
                            </div>
                          </th>
                          <th scope="col" width="5" className="text-nowrap">
                            <div className="">
                              <a href="/tokens?sort=holders&amp;order=desc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for descending sort">
                                Holders
                              </a>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>&nbsp;&nbsp;-</td>
                              <td>
                                <div className="media">
                                  <img className="u-xs-avatar mr-2" src="/images/main/empty-token.png" />
                                  <div className="media-body">
                                    <h3 className="h6 mb-0">
                                      <a className="text-primary" href={`/token/${item.token_address}`}>
                                        {item.token_name} ({item.token_symbol})
                                      </a>
                                    </h3>
                                    <span
                                      className="u-label u-label--xs u-label--badge-out u-label--secondary mt-1 d-none d-md-inline-block"
                                      style={{letterSpacing: '0px'}}
                                      data-toggle="tooltip"
                                      data-original-title={`Caduceus ${item.token_types} Token Type`}
                                    >
                                      {item.token_types}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-nowrap">
                                ${item.price_dollar}
                                <div className="small text-secondary">
                                  {item.price_btc}&nbsp;Btc<span className="d-block">{item.price_ht}&nbsp;CMP</span>
                                </div>
                              </td>
                              <td>
                                <span className={`${item.change.indexOf('-') === 0 ? 'text-danger' : 'text-success'} text-nowrap`}>
                                  <i className={`fa ${item.change.indexOf('-') === 0 ? 'fa-caret-down' : 'fa-caret-up'}`}></i> {item.change}
                                </span>
                              </td>
                              <td>${item.volume_24h}</td>
                              <td>{item.market_cap}</td>
                              <td>{item.holders}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <form method="post" action="./tokens" id="ctl00">
                  <div id="ContentPlaceHolder1_divPagination" className="d-md-flex justify-content-between my-3">
                    <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                      Show
                      <select onChange={handleChangePageSize} defaultValue={length} className="custom-select custom-select-xs mx-2">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Records
                    </div>
                    <Pager current={state.body.start} total={totalPage} path="/tokens" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
