import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';

export default function ValidatorSet() {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      // length:JSON.parse(sessionStorage.getItem('2')),
      length:JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')) ,
    },
  });

  const handleChangePageSize = e => {
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const validatorSetListRequest = useRequest(
    body => ({
      url: '/blockBrowser/validator/validators/validatorSetList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    validatorSetListRequest.run(state.body);
  }, [state]);

  if (validatorSetListRequest.loading) {
    return <Loading />;
  }

  const data = validatorSetListRequest.data?.validator_set_list || [];
  const counts = validatorSetListRequest.data?.counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);
  const length=state.body.length;
  
  window.sessionStorage.setItem('2',JSON.stringify(state.body.length))

  return (
    <main id="content" role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 font-weight-normal mb-0">
              Validators Set Info
              <p className="mb-0 text-break small">
                <span className="small text-secondary"></span>
              </p>
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body">
            <div className="d-md-flex justify-content-between mb-4">
              <p className="mb-2 mb-md-0">
                <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>
                Showing {state.body.length} of total {counts} snapshots (taken at per minute intervals)
              </p>

              <Pager current={state.body.start} total={totalPage} path="/validatorset" />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <a href="#" data-toggle-commonjs="tooltip" data-original-title="Click to show Datetime Format">
                        Age
                      </a>
                    </th>
                    <th scope="col">Block</th>
                    <th scope="col">Validators</th>
                    <th scope="col">Total Voting Power</th>
                    <th scope="col">Total Jailed</th>
                    <th scope="col">Total Incoming</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    const now = new Date().getTime();
                    const time = moment(now - Number(item.block_time))
                      .startOf('minute')
                      .fromNow();

                    return (
                      <tr key={i}>
                        <td className="showDate" style={{display: 'none'}}>
                          <span rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="" data-original-title={item.block_time}>
                            {item.block_time}
                          </span>
                        </td>
                        <td className="showAge">
                          <span rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="" data-original-title={item.block_time}>
                            {time}
                          </span>
                        </td>
                        <td>
                          <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                        </td>
                        <td>
                          <span className="u-label u-label--value u-label--secondary text-dark rounded mr-1">
                            <a href={`/validatorset/snapshot/${item.block_no}`}>
                              <i className="fal fa-user"></i> {item.validators}
                            </a>
                          </span>
                        </td>
                        <td>{item.total_voting_power} CMP</td>
                        <td>{item.total_jailed}</td>
                        <td>{item.total_incoming} CMP</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <form method="post" action="./validatorset" id="ctl00">
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                  Show
                  <select className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize} defaultValue={length}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Records
                </div>
                <Pager current={state.body.start} total={totalPage} path="/validatorset" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
