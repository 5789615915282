import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';

export default function ContractsVerified() {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [showType, setShowType] = useState(false);
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      // length: JSON.parse(sessionStorage.getItem('2')),
      length:JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')) ,
      field: 'contract_name',
      value: undefined,
      types: query.type, // open_source_license, solc, vyper, audit
    },
  });

  const contractsVerifiedListRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/contractsVerified/contractsVerifiedList',
      method: 'post',
      body: JSON.stringify({...body, field: body.value ? 'contract_name' : undefined}),
    }),
    {manual: true},
  );

  const handleChangePageSize = e => {
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  useEffect(() => {
    contractsVerifiedListRequest.run(state.body);
  }, [state]);

  if (contractsVerifiedListRequest.loading) {
    return <Loading />;
  }

  const data = contractsVerifiedListRequest.data?.contracts_verified_list || [];
  const counts = contractsVerifiedListRequest.data?.counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);
  const contracts_verified_count = contractsVerifiedListRequest.data?.counts || 0;
  const length=state.body.length;
  
  window.sessionStorage.setItem('2',JSON.stringify(state.body.length))

  return (
    <main id="content" role="main">
      <div className="bg-light py-3">
        <div className="container">
          <h1 className="h4 font-weight-normal mb-0">
            Contracts <span className="small text-secondary">With verified source codes only</span>
          </h1>
        </div>
      </div>
      <span id="ContentPlaceHolder1_lblAdResult"></span>
      <div id="ContentPlaceHolder1_mainrow" className="container space-bottom-2">
        <div className="card">
          <div className="card-header d-md-flex justify-content-between align-items-center">
            <div className="d-flex flex-wrap">
              <div className="position-relative mr-2 mb-2 mb-md-0">
                <a
                  id="selectType"
                  className="btn btn-xss btn-custom dropdown-toggle"
                  href="#"
                  role="button"
                  aria-controls="selectTypeButton"
                  aria-haspopup="true"
                  aria-expanded={String(showType)}
                  data-unfold-target="#selectTypeButton"
                  data-unfold-type="css-animation"
                  data-unfold-duration="300"
                  data-unfold-delay="300"
                  data-unfold-hide-on-scroll="false"
                  data-unfold-animation-in="slideInUp"
                  data-unfold-animation-out="fadeOut"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Select View / Filter Type"
                  onClick={e => {
                    setShowType(!showType);
                  }}
                >
                  Select View / Filter Type
                  <span className="text-secondary mx-1">|</span> Latest 500 Contracts
                </a>
                <div
                  id="selectTypeButton"
                  className={`dropdown-menu dropdown-unfold u-unfold--css-animation ${showType ? 'slideInUp' : 'u-unfold--hidden'}`}
                  aria-labelledby="selectType"
                  style={{minWidth: '200px', animationDuration: '300ms'}}
                >
                  <a className="dropdown-item" href="/contractsVerified" data-toggle="tooltip" title="" data-original-title="Show up to the latest 500 verified contract source codes">
                    Latest {contracts_verified_count} Contracts Verified
                  </a>
                  <hr className="my-1" />
                  <a
                    className="dropdown-item"
                    href="/contractsVerified?type=open_source_license"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Display Contract Source codes with an Open Source License"
                  >
                    <i className="fas fa-lock-open-alt"></i> Open Source License
                  </a>
                  <hr className="my-1" />
                  <a className="dropdown-item" href="/contractsVerified?type=solc">
                    <img className="mr-1" src="/images/compiler/Solidity_logo.png?v=0.0.2" width="13" />
                    Solidity Compiler
                  </a>
                  <a className="dropdown-item" href="/contractsVerified?type=vyper">
                    <img className="mr-1" src="/images/compiler/Vyper_logo.png?v=0.0.2" width="13" />
                    Vyper Compiler
                  </a>
                  <a className="dropdown-item" href="/contractsVerified?type=audit" data-toggle="tooltip" title="" data-original-title="Display Contract Source codes with audit report">
                    <i className="far fa-file-alt ml-1 mr-1 text-secondary"></i>Contract Security Audit
                  </a>
                </div>
              </div>
              <div className="mr-2 mb-2 mb-md-0">
                <h2 className="card-header-title"></h2>
              </div>
              <div className="mr-2 mb-2 mb-md-0"></div>
            </div>
            <div className="d-flex ml-auto">
              <div className="position-relative order-1 order-md-2" data-toggle="tooltip" title="" data-original-title="Search Contract Name">
                <a
                  id="searchFilterInvoker"
                  className="btn btn-sm btn-icon btn-primary"
                  href="#"
                  role="button"
                  aria-controls="searchFilter"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-unfold-target="#searchFilter"
                  data-unfold-type="css-animation"
                  data-unfold-duration="300"
                  data-unfold-delay="300"
                  data-unfold-hide-on-scroll="false"
                  data-unfold-animation-in="slideInUp"
                  data-unfold-animation-out="fadeOut"
                >
                  <i className="fa fa-search btn-icon__inner"></i>
                </a>
                <div
                  id="searchFilter"
                  className="dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation u-unfold--hidden"
                  aria-labelledby="searchFilterInvoker"
                  style={{width: '265px', animationDuration: '300ms'}}
                >
                  <form action="/contractsVerified" method="get" className="js-focus-state input-group input-group-sm w-100">
                    <input name="cn" type="search" maxLength="60" value="" className="form-control" placeholder="Search Contract Name" readOnly={true}/>
                    <input name="filter" type="hidden" value="" readOnly={true}/>
                    <div className="input-group-append">
                      <button type="submit" className="btn btn-primary" data-toggle="tooltip" title="" data-original-title="Search Contract Name">
                        Find
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="transfers" role="tabpanel" aria-labelledby="contractsverified-tab">
                <div className="d-md-flex justify-content-between mb-4">
                  <p className="mb-2 mb-md-0">
                    <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>
                    &nbsp;Showing the last {contracts_verified_count} verified contracts source code
                  </p>
                  <Pager path="/contractsVerified" current={state.body.start} total={totalPage} />
                </div>
                <div className="table-responsive mb-2 mb-md-0">
                  <table className="table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" width="1">
                          Address
                        </th>
                        <th scope="col">Contract Name</th>
                        <th scope="col">Compiler</th>
                        <th scope="col">Version</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Txns</th>
                        <th scope="col">Setting</th>
                        <th scope="col">Verified</th>
                        <th scope="col">
                          Audited
                          <span data-toggle="tooltip" title="" size="2" data-original-title="Smart Contracts Audit and Security">
                            <i className="far fa-info-circle"></i>
                          </span>
                        </th>
                        <th scope="col">
                          License
                          {/* <a href="/contract-license-types" target="_blank" data-toggle="tooltip" title="" data-original-title="Contract Source Code License Type, click for more info">
                            <i className="far fa-info-circle"></i>
                          </a> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(item => {
                        return (
                          <tr key={item.address}>
                            <td>
                              <span data-toggle="tooltip" title="" size="2" data-original-title="Verified Code">
                                <i className="fas fa-check-circle text-success"></i>
                              </span>
                              <a data-toggle="tooltip" title="" href={`/address/${item.address}#contracts`} className="hash-tag text-truncate" data-original-title={item.address}>
                                {item.address}
                              </a>
                            </td>
                            <td>{item.contract_name}</td>
                            <td>{item.compiler}</td>
                            <td>
                              <span data-boundary="viewport" data-toggle="tooltip" title="" data-original-title="7 known solidity compiler vulnerabilities exists for this compiler version">
                                <i className="fa fa-exclamation-triangle text-warning"></i>
                              </span>
                              {item.version}
                            </td>
                            <td>{item.balance} CMP</td>
                            <td>{item.trade_amount}</td>
                            <td>
                              {item.setting_optimization_enabled ? (
                                <span aria-hidden="true" className="btn btn-xs btn-icon btn-soft-secondary rounded-circle" data-toggle="tooltip" title="" data-original-title="Optimization Enabled">
                                  <i className="fas fa-bolt btn-icon__inner"></i>
                                </span>
                              ) : undefined}
                              {item.setting_library ? (
                                <span aria-hidden="true" className="btn btn-xs btn-icon btn-soft-secondary rounded-circle" data-toggle="tooltip" title="" data-original-title="Library Used">
                                  <i className="fas fa-book-open btn-icon__inner"></i>
                                </span>
                              ) : undefined}
                              {item.setting_constrator ? (
                                <span aria-hidden="true" className="btn btn-xs btn-icon btn-soft-secondary rounded-circle" data-toggle="tooltip" title="" data-original-title="Constructor Arguments">
                                  <i className="fas fa-wrench btn-icon__inner"></i>
                                </span>
                              ) : undefined}
                            </td>
                            <td>{item.verified}</td>
                            <td>{item.audited}</td>
                            <td>{item.license}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <form method="post" action="./contractsVerified" id="ctl00">
                  <div className="d-md-flex justify-content-between my-3">
                    <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                      <span id="ContentPlaceHolder1_pageRecords" data-toggle="tooltip" title="" data-original-title="Records per Page">
                        Show
                        <select className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize} defaultValue={length}>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Records
                      </span>
                    </div>
                    <Pager path="/contractsVerified" current={state.body.start} total={totalPage} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="text-right mr-1">
          <br />
          Source Code <a href="source-code-usage-terms">Terms of Usage.</a>
        </div> */}
      </div>
    </main>
  );
}
